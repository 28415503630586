// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// CONSTANTES PAGINAS
import {
  PAGINA_LOGIN,
  PAGINA_RECUPERAR_CONTRASENIA,
  PAGINA_ESTADOS,
  PAGINA_REGISTRO_SANDIAS_AGRICULTOR,
  PAGINA_REGISTRO_SANDIAS_CLIENTE,
  PAGINA_LISTA_CLIENTES,
  PAGINA_REGISTRO_CLIENTE,
  PAGINA_LISTA_AGRICULTORES,
  PAGINA_DATOS_CLIENTE,
  PAGINA_REGISTRO_AGRICULTOR,
  PAGINA_DATOS_AGRICULTOR,
  PAGINA_UTILIDADES,
  PAGINA_DATOS_UTILIDADES,
  PAGINA_FONDOS,
  PAGINA_ASIGNAR_ESTADO
} from './constants';

// GUARDIAN
import { ValidarCredencialesGuard } from './guardias/validar-credenciales.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: PAGINA_LOGIN,
    pathMatch: 'full'
  },
  {
    path: PAGINA_LOGIN,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: PAGINA_RECUPERAR_CONTRASENIA,
    loadChildren: () => import('./pages/recuperar-contrasenia/recuperar-contrasenia.module').then(m => m.RecuperarContraseniaPageModule)
  },
  {
    path: PAGINA_ESTADOS,
    loadChildren: () => import('./pages/estados/estados.module').then(m => m.EstadosPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA' },
  },
  {
    path: PAGINA_REGISTRO_SANDIAS_AGRICULTOR,
    loadChildren: () => import('./pages/registro-sandia-agricultor/registro-sandia-agricultor.module').then(m => m.RegistroSandiaAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_REGISTRO_SANDIAS_CLIENTE,
    loadChildren: () => import('./pages/registro-sandia-cliente/registro-sandia-cliente.module').then(m => m.RegistroSandiaClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // CREAR DEPOSITOS CLIENTE
  {
    path: 'datos-cliente/:cliente/depositos/crear/:saldo/:nombre',
    loadChildren: () => import('./pages/depositos-cliente/depositos-cliente.module').then(m => m.DepositosClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // CREAR DEPOSITOS AGRICULTOR
  {
    path: 'datos-agricultor/:agricultor/depositos/crear/:saldo/:nombre',
    loadChildren: () => import('./pages/depositos-agricultor/depositos-agricultor.module').then(m => m.DepositosAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // CREAR MERMAS CLIENTE
  {
    path: 'datos-cliente/:cliente/mermas/crear/:saldo/:nombre',
    loadChildren: () => import('./pages/mermas-cliente/mermas-cliente.module').then(m => m.MermasClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_LISTA_CLIENTES,
    loadChildren: () => import('./pages/lista-clientes/lista-clientes.module').then(m => m.ListaClientesPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA' },
  },
  {
    path: PAGINA_LISTA_AGRICULTORES,
    loadChildren: () => import('./pages/lista-agricultores/lista-agricultores.module').then(m => m.ListaAgricultoresPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_REGISTRO_CLIENTE,
    loadChildren: () => import('./pages/registro-cliente/registro-cliente.module').then(m => m.RegistroClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_REGISTRO_AGRICULTOR,
    loadChildren: () => import('./pages/registro-agricultor/registro-agricultor.module').then(m => m.RegistroAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'datos-cliente/:cliente/mermas/:registro/:saldo/:cliente/:kilogramos/:precio_kilogramo/:numero_viaje',
    loadChildren: () => import('./pages/mermas/mermas.module').then(m => m.MermasPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA-CLIENTE' },
  },
  {
    path: PAGINA_LISTA_AGRICULTORES,
    loadChildren: () => import('./pages/lista-agricultores/lista-agricultores.module').then(m => m.ListaAgricultoresPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_DATOS_CLIENTE,
    loadChildren: () => import('./pages/datos-cliente/datos-cliente.module').then(m => m.DatosClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA-CLIENTE' },
  },
  // CLIENTE
  {
    path: 'datos-cliente/:cliente/historial/:cliente/:nombre/:saldo',
    loadChildren: () => import('./pages/historial-cliente/historial-cliente.module').then(m => m.HistorialClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'datos-cliente/:cliente/depositos/:cliente/:nombre/:saldo',
    loadChildren: () => import('./pages/lista-depositos-cliente/lista-depositos-cliente.module').then(m => m.ListaDepositosClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA-CLIENTE' },
  },
  {
    path: 'datos-cliente/:cliente/mermas/:cliente/:nombre/:saldo',
    loadChildren: () => import('./pages/lista-mermas-cliente/lista-mermas-cliente.module').then(m => m.ListaMermasClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA-CLIENTE' },
  },
  {
    path: 'datos-cliente/:cliente/mermas/:cliente/:nombre/:saldo/editar/:merma',
    loadChildren: () => import('./pages/mermas-cliente-edicion/mermas-cliente-edicion.module').then(m => m.MermasClienteEdicionPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'editar-cliente/:cliente',
    loadChildren: () => import('./pages/registro-cliente/registro-cliente.module').then(m => m.RegistroClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // AGRICULTOR
  {
    path: 'datos-agricultor/:agricultor/historial/:agricultor/:nombre/:saldo',
    loadChildren: () => import('./pages/historial-agricultor/historial-agricultor.module').then(m => m.HistorialAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'datos-agricultor/:agricultor/depositos/:agricultor/:nombre/:saldo',
    loadChildren: () => import('./pages/lista-depositos-agricultor/lista-depositos-agricultor.module').then(m => m.ListaDepositosAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-AGRICULTOR' },
  },
  {
    path: 'datos-agricultor/:agricultor/depositos/:agricultor/:nombre/:saldo/editar/:deposito',
    loadChildren: () => import('./pages/edicion-deposito-agricultor/edicion-deposito-agricultor.module').then(m => m.EdicionDepositoAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-AGRICULTOR' },
  },
  {
    path: PAGINA_REGISTRO_AGRICULTOR,
    loadChildren: () => import('./pages/registro-agricultor/registro-agricultor.module').then(m => m.RegistroAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_DATOS_AGRICULTOR,
    loadChildren: () => import('./pages/datos-agricultor/datos-agricultor.module').then(m => m.DatosAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-AGRICULTOR' },
  },
  {
    path: 'editar-agricultor/:agricultor',
    loadChildren: () => import('./pages/registro-agricultor/registro-agricultor.module').then(m => m.RegistroAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_UTILIDADES,
    loadChildren: () => import('./pages/utilidades/utilidades.module').then(m => m.UtilidadesPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'registro-utilidad',
    loadChildren: () => import('./pages/registro-utilidad/registro-utilidad.module').then(m => m.RegistroSandiaClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: PAGINA_DATOS_UTILIDADES,
    loadChildren: () => import('./pages/datos-utilidades/datos-utilidades.module').then(m => m.DatosUtilidadesPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'edicion-registro-sandia-cliente',
    loadChildren: () => import('./pages/edicion-registro-sandia-cliente/edicion-registro-sandia-cliente.module').then(m => m.EdicionRegistroSandiaClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'edicion-registro-cliente',
    loadChildren: () => import('./pages/edicion-registro-cliente/edicion-registro-cliente.module').then(m => m.EdicionRegistroClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // HISTORIAL MERMA
  {
    path: 'datos-cliente/:cliente/mermas/:cliente/:nombre/:saldo/historial/:merma',
    loadChildren: () => import('./pages/mermas-cliente-historial/mermas-cliente-historial.module').then(m => m.HistorialMermasClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // ACTUALIZAR SANDIA CLIENTE
  {
    path: 'datos-cliente/sandiaCliente/:cliente/editar/:nombre/:sandiaCliente',
    loadChildren: () => import('./pages/edicion-registro-sandia-cliente/edicion-registro-sandia-cliente.module').then(m => m.EdicionRegistroSandiaClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // ACTUALIZAR SANDIA AGRICULTOR
  {
    path: 'datos-agricultor/sandiaAgricultor/:agricultor/editar/:nombre/:sandiaAgricultor',
    loadChildren: () => import('./pages/edicion-registro-sandia-agricultor/edicion-registro-sandia-agricultor.module').then(m => m.EdicionRegistroSandiaAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // HISTORIAL, SANDIA CLIENTE
  {
    path: 'datos-cliente/sandiaCliente/:cliente/historial/:nombre/:sandiaCliente',
    loadChildren: () => import('./pages/registro-sandia-cliente-historial/registro-sandia-cliente-historial.module').then(m => m.RegistroSandiaClienteHistorialPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // HISTORIAL, SANDIA AGRICULTOR
  {
    path: 'datos-agricultor/sandiaAgricultor/:agricultor/historial/:nombre/:sandiaAgricultor',
    loadChildren: () => import('./pages/registro-sandia-agricultor-historial/registro-sandia-agricultor-historial.module').then(m => m.RegistroSandiaAgricultorHistorialPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // FONDOS
  {
    path: PAGINA_FONDOS,
    loadChildren: () => import('./pages/fondos/fondos.module').then(m => m.FondosPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA' },
  },
  {
    path: 'fondos/registrarCobranza',
    loadChildren: () => import('./pages/registro-cobranza-del-dia/registro-cobranza-del-dia.module').then(m => m.RegistroCobranzaDelDiaPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  {
    path: 'fondos/registrarDeposito',
    loadChildren: () => import('./pages/registro-deposito-del-dia/registro-deposito-del-dia.module').then(m => m.RegistroDepositoDelDiaPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // EDITAR DEPOSITO DEL DIA
  {
    path: 'fondos/editar/depositos/:dato/:normal',
    loadChildren: () => import('./pages/edicion-deposito-del-dia/edicion-deposito-del-dia.module').then(m => m.EdicionDepositoDelDiaPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // EDITAR COBRANZA DEL DIA
  {
    path: 'fondos/editar/cobranza/:dato/:normal',
    loadChildren: () => import('./pages/edicion-cobranza-del-dia/edicion-cobranza-del-dia.module').then(m => m.EdicionCobranzaDelDiaPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // HISTORIAL DEPOSITO DEL DIA
  {
    path: 'fondos/historial/depositos/:dato/:normal',
    loadChildren: () => import('./pages/deposito-del-dia-historial/deposito-del-dia-historial.module').then(m => m.DepositoDelDiaHistorialPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // HISTORIAL COBRANZA DEL DIA
  {
    path: 'fondos/historial/cobranza/:dato/:normal',
    loadChildren: () => import('./pages/cobranza-del-dia-historial/cobranza-del-dia-historial.module').then(m => m.CobranzaDelDiaHistorialPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },

  // ASIGNAR ESTADO CLIENTE
  {
    path: PAGINA_ASIGNAR_ESTADO,
    loadChildren: () => import('./pages/asignar-estado-cliente/asignar-estado-cliente.module').then(m => m.AsignarEstadoClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // MERMAS REGISTRO SANDIA AGRICULTOR
  {
    path: 'datos-agricultor/mermas/:registrosandiaagricultor',
    loadChildren: () => import('./pages/lista-mermas-cliente/lista-mermas-cliente.module').then(m => m.ListaMermasClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA-CLIENTE' },
  },
  // CREAR MERMAS AGRICULTOR
  {
    path: 'datos-agricultor/mermas/crear/:registrosandiaagricultor',
    loadChildren: () => import('./pages/mermas-cliente/mermas-cliente.module').then(m => m.MermasClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // MERMAS POR AGRICULTOR
  {
    path: 'datos-agricultor/todosregistros/mermas/:agricultor',
    loadChildren: () => import('./pages/lista-mermas-agricultor/lista-mermas-agricultor.module').then(m => m.ListaMermasAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR-COBRANZA-AGRICULTOR' },
  },
  // CREAR MERMAS AGRICULTOR SOLO AGRICULTOR
  {
    path: 'datos-agricultor/mermas/solo/crear/:agricultor/:soloagricultor',
    loadChildren: () => import('./pages/mermas-cliente/mermas-cliente.module').then(m => m.MermasClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // NUEVA PESTAÑA PARA REGISTRAR COMPRA (SANDIAS REGISTRO AGRICULTOR)
  {
    path: 'registrar/compra/:agricultor',
    loadChildren: () => import('./pages/registro-sandia-agricultor/registro-sandia-agricultor.module').then(m => m.RegistroSandiaAgricultorPageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
  // CLIENTE
  // NUEVA PESTAÑA PARA REGISTRAR VENTAS (SANDIAS REGISTRO CLIENTE)
  {
    path: 'registrar/venta/:cliente',
    loadChildren: () => import('./pages/registro-sandia-cliente/registro-sandia-cliente.module').then(m => m.RegistroSandiaClientePageModule),
    canActivate: [ValidarCredencialesGuard], data: { permiso: 'ADMINISTRADOR' },
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
