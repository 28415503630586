import { createReducer, on } from '@ngrx/store';
import {
  setIsOnLine,
  setEsMovil,
  setEnSesion,
  setUsuario,
  unSetUsuario,
  setEstado,
  unSetEstado,
  setFechaFondos,
  setEsListaCobranza
} from './app.actions';

// INTERFACES
import { IUsuario, ICatalogo } from 'src/app/interfaces';

export interface State {
  isOnLine: boolean;
  esMovil: boolean;
  enSesion: boolean;
  usuario: IUsuario;
  estado: ICatalogo;
  fechaFondos: string;
  esListaCobranza: boolean;
}

export const initialState: State = {
  isOnLine: null,
  esMovil: null,
  enSesion: null,
  usuario: null,
  estado: null,
  fechaFondos: null,
  esListaCobranza: null
};

// tslint:disable-next-line:variable-name
const _appReducer = createReducer(initialState,

  on(setIsOnLine, (state, { isOnLine }) => ({ ...state, isOnLine })),
  on(setEsMovil, (state, { esMovil }) => ({ ...state, esMovil })),
  on(setEnSesion, (state, { enSesion }) => ({ ...state, enSesion })),
  on(setUsuario, (state, { usuario }) => ({ ...state, usuario: { ...usuario } })),
  on(unSetUsuario, state => ({ ...state, usuario: null })),
  on(setEstado, (state, { estado }) => ({ ...state, estado: { ...estado } })),
  on(unSetEstado, state => ({ ...state, estado: null })),
  on(setFechaFondos, (state, { fechaFondos }) => ({ ...state, fechaFondos })),
  on(setEsListaCobranza, (state, { esListaCobranza }) => ({ ...state, esListaCobranza }))

);

export function appReducer(state: State, action: any) {
  return _appReducer(state, action);
}
