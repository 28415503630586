import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

// INTERFACES
import { IUsuario } from 'src/app/interfaces';

@Component({
  selector: 'app-cliente-opciones-popover',
  template: `
  <ion-list [mode]="'ios'">
    <ion-item *ngIf="usuario?.rol === 'ADMINISTRADOR' && mermas" class="mermas-opcion-menu cursor-pointer" (click)="send('mermas')" lines="none">
      <ion-label>Mermas</ion-label>
    </ion-item>
    <ion-item *ngIf="usuario?.rol === 'ADMINISTRADOR' && !sinHistorial" class="opcion-menu-select" (click)="send('historial')" lines="none">
      <ion-label>Historial</ion-label>
    </ion-item>
    <ion-item *ngIf="usuario?.rol === 'ADMINISTRADOR'  && !sinEditar" class="opcion-menu-select" (click)="send('editar')" lines="none">
      <ion-label>Editar</ion-label>
    </ion-item>
    <ion-item *ngIf="usuario?.rol === 'ADMINISTRADOR' && eliminar" class="opcion-menu-select-eliminar" (click)="send('eliminar')" lines="none">
      <ion-label>Eliminar</ion-label>
    </ion-item>
    <hr class="hr-menu"/>
    <ion-item class="opcion-menu-select" style="color: #ED3237 !important; font-weight: 600 !important;" (click)="send(null)" lines="none">
      <ion-label>Cancelar</ion-label>
    </ion-item>
  </ion-list>`
})

export class OpcionesPopoverComponent implements OnInit {

  @Input() usuario: IUsuario;
  @Input() sinHistorial: boolean;
  @Input() sinEditar: boolean;
  @Input() eliminar: boolean;
  @Input() mermas: boolean;
  constructor(private popoverController: PopoverController) { }

  ngOnInit(): void {
  }

  send(opcion: string) {

    this.popoverController.dismiss(opcion);

  }

}
